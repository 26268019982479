/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require.context('../images/', true, /\.(gif|jpg|png|svg|eot|ttf|woff|woff2)$/i)

import 'jquery'
window.jQuery = $
window.$ = $

import 'jquery-ujs'

require("@rails/ujs").start()

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import '@materializecss/materialize'
import 'moment';
import 'slick-carousel';
import { autocomplete } from 'jquery-ui'
import '../javascripts/custom/picker.date'
import 'jquery-validation'
import 'jquery-validation/dist/localization/messages_de'

import '../channels'

import '../stylesheets/application'
import '../javascripts/application.js'
