import './custom/materialize-stepper'

const checkPostalAddress = () => {
  if ($('input#plant_different_postal_address').is(':checked')) {
    $('div.postal_address_fields').show()
    $("input#plant_owner_address_attributes_first_name").prop("required", true).prop("aria-required", true).addClass("required")
    $("input#plant_owner_address_attributes_last_name").prop("required", true).prop("aria-required", true).addClass("required")
    $("input#plant_owner_address_attributes_street_name").prop("required", true).prop("aria-required", true).addClass("required")
    $("input#plant_owner_address_attributes_zip").prop("required", true).prop("aria-required", true).addClass("required")
    $("input#plant_owner_address_attributes_city").prop("required", true).prop("aria-required", true).addClass("required")
    $("input#plant_owner_address_attributes_mobile").prop("required", true).prop("aria-required", true).addClass("required")
    $("input#plant_owner_address_attributes_email").prop("required", true).prop("aria-required", true).addClass("required")
  } else {
    $('div.postal_address_fields').hide()
    $("input#plant_owner_address_attributes_first_name").removeAttr("required").removeAttr("aria-required").removeClass("required")
    $("input#plant_owner_address_attributes_last_name").removeAttr("required").removeAttr("aria-required").removeClass("required")
    $("input#plant_owner_address_attributes_street_name").removeAttr("required").removeAttr("aria-required").removeClass("required")
    $("input#plant_owner_address_attributes_zip").removeAttr("required").removeAttr("aria-required").removeClass("required")
    $("input#plant_owner_address_attributes_city").removeAttr("required").removeAttr("aria-required").removeClass("required")
    $("input#plant_owner_address_attributes_mobile").removeAttr("required").removeAttr("aria-required").removeClass("required")
    $("input#plant_owner_address_attributes_email").removeAttr("required").removeAttr("aria-required").removeClass("required")
  }
}

$(document).ready(() => {

  checkPostalAddress()

  $('.stepper').activateStepper()

  $('input#plant_different_postal_address').change(() => {
    checkPostalAddress()
  })
})
