import consumer from "./consumer"

window.addEventListener('load', function() {
  let paths = window.location.pathname.split('/')
  let reverse_auction_id = paths[1] == "reverse_auctions" ? paths[2] : null
  let reverseAuctionShow = document.getElementById('reverse-auction-show')

  if (reverseAuctionShow) {
    consumer.subscriptions.create({
      channel: "ReverseAuctionChannel",
      reverse_auction_id: reverse_auction_id
    },{

    received(data) {
      let offersTableBody = document.getElementById('best_bids')
      let numberOptions = {
        minimumFractionDigits: 1,
      }
      let totalOffers = 0
      data.forEach((offer, index) => {
        if (document.reverse_auction.auction_type == 'classic') {
          totalOffers += offer.delivery_quantity
        } else {
          totalOffers += offer.delivery_quantity / 1000
        }
        let offerRow = document.getElementById('offer-' + offer.id)
        if (offerRow) {
          if (!offerRow.classList.contains(offer.status)) {
            offerRow.className = "";
            offerRow.classList.add(offer.status)
          }
          let indexElement = offerRow.querySelector('.index')
          indexElement.textContent = (index + 1).toString()
          let price = offerRow.querySelector('.price')
          if (document.reverse_auction.auction_type == 'classic') {
            price.textContent = (offer.price).toLocaleString('de-CH', numberOptions)
          } else {
            price.textContent = (offer.price * 10).toLocaleString('de-CH', numberOptions)
          }
          let quantity = offerRow.querySelector('.quantity')
          if (document.reverse_auction.auction_type == 'classic') {
            quantity.textContent = (offer.delivery_quantity).toLocaleString('de-CH')
          } else {
            quantity.textContent = (offer.delivery_quantity / 1000).toLocaleString('de-CH', numberOptions)
          }
          let status = offerRow.querySelector('.status')
          if (offer.status == 'inside_contigent') {
            status.textContent = 'Im Kontingent'
          } else {
            status.textContent = 'Nicht im Kontingent'
          }
          offersTableBody.appendChild(offerRow)
        } else {
          offerRow = document.createElement('tr')
          offerRow.id = 'offer-' + offer.id
          offerRow.classList.add(offer.status)
          let indexElement = document.createElement('td')
          let indexWrapper = document.createElement('div')
          indexWrapper.classList.add('valign-wrapper')
          let indexInner = document.createElement('div')
          indexInner.classList.add('index')
          indexInner.appendChild(document.createTextNode((index + 1).toString()))
          indexWrapper.appendChild(indexInner)
          indexElement.appendChild(indexWrapper)
          let price = document.createElement('td')
          price.classList.add('price')
          if (document.reverse_auction.auction_type == 'classic') {
            price.appendChild(document.createTextNode((offer.price).toLocaleString('de-CH', numberOptions)))
          } else {
            price.appendChild(document.createTextNode((offer.price * 10).toLocaleString('de-CH', numberOptions)))
          }
          let quantity = document.createElement('td')
          quantity.classList.add('quantity')
          if (document.reverse_auction.auction_type == 'classic') {
            quantity.appendChild(document.createTextNode((offer.delivery_quantity).toLocaleString('de-CH')))
          } else {
            quantity.appendChild(document.createTextNode((offer.delivery_quantity / 1000).toLocaleString('de-CH', numberOptions)))
          }
          let status = document.createElement('td')
          status.classList.add('status')
          if (offer.status == 'inside_contigent') {
            status.appendChild(document.createTextNode('Im Kontingent'))
          } else {
            status.appendChild(document.createTextNode('Nicht im Kontingent'))
          }
          let empty = document.createElement('td')
          empty.classList.add('offerder')
          offerRow.appendChild(indexElement)
          offerRow.appendChild(price)
          offerRow.appendChild(quantity)
          offerRow.appendChild(status)
          offerRow.appendChild(empty)
          offersTableBody.appendChild(offerRow)
        }
      })
      let totalCapacity = parseFloat(document.querySelector('.total-capacity span:last-of-type').textContent.replace(/'/g, ''), 10)
      let remainingCapacity = totalCapacity - totalOffers
      if (remainingCapacity < 0) { remainingCapacity = 0 }
      if (document.reverse_auction.auction_type == 'classic') {
        document.querySelector('.remaining-capacity span:last-of-type').textContent = remainingCapacity.toLocaleString('de-CH') + ' kWh'
      } else {
        document.querySelector('.remaining-capacity span:last-of-type').textContent = remainingCapacity.toLocaleString('de-CH', numberOptions) + ' MWh'
      }
    }
  });
  }
})
