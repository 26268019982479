import consumer from "./consumer"


window.addEventListener('load', function() {
  let paths = window.location.pathname.split('/')
  let auction_id = paths[1] == "auctions" ? paths[2] : null
  let auctionShow = document.getElementById('auction-show')

  if (auctionShow) {
    consumer.subscriptions.create({
      channel: "AuctionChannel",
      auction_id: auction_id
    },{

    received(data) {
      let bidsTableBody = document.getElementById('best_bids')
      let numberOptions = {
        minimumFractionDigits: 1,
      }
      let totalBids = 0
      data.forEach((bid, index) => {
        let bidRow = document.getElementById('bid-' + bid.id)
        totalBids += bid.quantity_taken
        if (bidRow) {
          if (!bidRow.classList.contains(bid.status)) {
            bidRow.className = "";
            bidRow.classList.add(bid.status)
          }
          let indexElement = bidRow.querySelector('.index')
          indexElement.textContent = (index + 1).toString()
          let price = bidRow.querySelector('.price')
          price.textContent = (bid.price * 10).toLocaleString('de-CH', numberOptions)
          let quantity = bidRow.querySelector('.quantity')
          quantity.textContent = (bid.quantity_taken / 1000).toLocaleString('de-CH', numberOptions)
          let status = bidRow.querySelector('.status')
          if (bid.status == 'inside_contigent') {
            status.textContent = 'Im Kontingent'
          } else {
            status.textContent = 'Nicht im Kontingent'
          }
          bidsTableBody.appendChild(bidRow)
        } else {
          bidRow = document.createElement('tr')
          bidRow.id = 'bid-' + bid.id
          bidRow.classList.add(bid.status)
          let indexElement = document.createElement('td')
          let indexWrapper = document.createElement('div')
          indexWrapper.classList.add('valign-wrapper')
          let indexInner = document.createElement('div')
          indexInner.classList.add('index')
          indexInner.appendChild(document.createTextNode((index + 1).toString()))
          indexWrapper.appendChild(indexInner)
          indexElement.appendChild(indexWrapper)
          let price = document.createElement('td')
          price.classList.add('price')
          price.appendChild(document.createTextNode((bid.price * 10).toLocaleString('de-CH', numberOptions)))
          let quantity = document.createElement('td')
          quantity.classList.add('quantity')
          quantity.appendChild(document.createTextNode((bid.quantity_taken / 1000).toLocaleString('de-CH', numberOptions)))
          let status = document.createElement('td')
          status.classList.add('status')
          if (bid.status == 'inside_contigent') {
            status.appendChild(document.createTextNode('Im Kontingent'))
          } else {
            status.appendChild(document.createTextNode('Nicht im Kontingent'))
          }
          let empty = document.createElement('td')
          empty.classList.add('bidder')
          bidRow.appendChild(indexElement)
          bidRow.appendChild(price)
          bidRow.appendChild(quantity)
          bidRow.appendChild(status)
          bidRow.appendChild(empty)
          bidsTableBody.appendChild(bidRow)
        }
      })
      let totalCapacity = parseInt(document.querySelector('.total-capacity span:last-of-type').textContent.replace(/'/g, ''), 10)
      let remainingCapacity = totalCapacity - totalBids
      if (remainingCapacity < 0) { remainingCapacity = 0 }
      document.querySelector('.remaining-capacity span:last-of-type').textContent = remainingCapacity.toLocaleString('de-CH', numberOptions) + ' MWh'
    }
  });
  }
})
