import consumer from './consumer'

consumer.subscriptions.create("ProgressChannel", {
  received: (data) => {
    $('div.progress-row').show();
    $('div#processed').html(data.progress);
    $('div#processed_of').html(data.of_rows);
    if (data.working == false) {
      $('div.progress-row').hide();
      location.reload();
    }
  }
})
