// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//

import './auctions.js'
import './plants.js'
import './reverse_auctions.js'
import './custom/cookies.js'

const Sortable = require('./custom/sortable.js');

document.addEventListener('DOMContentLoaded', function() {
  var collabsibelElems = document.querySelectorAll('.collapsible');
  var collabsibleInstances = M.Collapsible.init(collabsibelElems);

  var selectElems = document.querySelectorAll('select');
  var instances = M.FormSelect.init(selectElems, {
    dropdownOptions: {
      container: document.body
    }
  });

  var modalElems = document.querySelectorAll('.modal');
  var instances = M.Modal.init(modalElems);
});


$(document).ready(function() {
  $('select[required]').css({
      display: 'inline',
      position: 'absolute',
      float: 'left',
      padding: 0,
      margin: 0,
      height: 0,
      width: 0,
      top: '1em',
      left: '0em'
    });

  $("div.collapsible-header").click((ev) => {
    let that = $(ev.target)
    if (that.parent('li').hasClass('active')) {
      that.children('i').removeClass("r180")
    } else {
      that.parents('ul').find('i.r180').removeClass("r180")
      that.children('i').addClass("r180")
    }
  });

  // prevent forms from submitting on enter,
  // but allow enter in textareas
  $(document).on("keypress", ":input:not(textarea)", function(e) {
    if (e.keyCode == 13) {
        e.preventDefault();
    }
  });

  var ie = false;
  if (Object.hasOwnProperty.call(window, "ActiveXObject") && !window.ActiveXObject) {
    ie = true;
  }

  $('tr[data-link-blank]').on('click', (ev) => {
    window.open(ev.target.closest('tr').dataset.linkBlank, '_blank');
  });

  let calendar_closed=false;  // global variable to track if the calendar was closed.
  $('.datepicker').pickadate({
    selectMonths: true,
    selectYears: true,
    firstDay: 1,
    format: 'dd.mm.yyyy',
    editable: true,
    today: '',
    clear: 'Löschen',
    close: 'Abbrechen',
    monthsFull: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    weekdaysShort: [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ],
    onOpen: function() {
      if (calendar_closed) {
        this.close();  // calendar is a reference to the pickadate object
        if (ie) {
          // Wait a second before letting it be reopened again
          setTimeout(function() {calendar_closed = false;}, 500);
        } else {
          calendar_closed = false;
        }
      }
      let value = this.get('value', 'yyyy/mm/dd');
      this.set('select', value, { format: 'dd.mm.yyyy' });
    },
    onClose: function() {
      calendar_closed = true;
    }
  });
  $('.datepicker').on('blur', (ev) => {
    let value = $(ev.target).val();
    $(ev.target).attr('value', value);
  });

  let timeInputs = document.querySelectorAll('.timepicker')
  M.Timepicker.init(timeInputs, {
    twelveHour: false,
    i18n: {
      cancel: 'Abbrechen',
      clear: 'Löschen',
      done: 'Fertig'
    }
  });


  $.validator.setDefaults({
    errorClass: 'helper-text error-block red-text text-darken-1',
    validClass: "valid",
    // Allows inputs in hidden steps to be validated on form submission
    ignore: [],
    errorElement: "span",
    errorPlacement: function (error, element) {
      if (element.is(':radio') || element.is(':checkbox')) {
        error.insertBefore($(element).parent());
      }
      else {
        // insert after label
        error.insertAfter(element.next()); // default error placement.
        //element.closest('span').data('error', error);
        //element.next().attr('data-error', error);
      }
    },
    success: function (element) {
      if (!$(element).closest('li').find('label.invalid:not(:empty)').length) {
        $(element).closest('li').removeClass('wrong');
      }
    }
  });

  $('.slick-slider').slick({
    slidesToShow: 1,
    arrows: true,
    nextArrow: "<span class='slick-next material-icons'>keyboard_arrow_right</span>",
    prevArrow: "<span class='slick-prev material-icons'>keyboard_arrow_left</span>",
    mobileFirst: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: "unslick"
      }
    ]
  });

  $('.sidenav-trigger').on('click', function() {
    $('body').addClass('menu-open');
  });

  $('.close-modal, .menu-same-page').on('click', function() {
    $('body').removeClass('menu-open');
  });

  Sortable.init();

  // check if switch checkbox is checked
  let switchEl = $('.switch, .switch-old');

  if (switchEl.length > 0) {
    let switchCheckbox = $('[type="checkbox"]:checked');
    if (switchCheckbox.length > 0) {
      $('.switch .demand, .switch-old .demand').css('font-weight', 'bold');
      $('.switch .supply, .switch-old .supply').css('font-weight', 'normal');
    } else {
      $('.switch .demand, .switch-old .demand').css('font-weight', 'normal');
      $('.switch .supply, .switch-old .supply').css('font-weight', 'bold');
    }
    $('.switch input[type="checkbox"], .switch-old input[type="checkbox"]').on('change', function() {
      let switchCheckbox = $('[type="checkbox"]:checked');
      if (switchCheckbox.length > 0) {
        $('.switch .demand, .switch-old .demand').css('font-weight', 'bold');
        $('.switch .supply, .switch-old .supply').css('font-weight', 'normal');
      } else {
        $('.switch .demand, .switch-old .demand').css('font-weight', 'normal');
        $('.switch .supply, .switch-old .supply').css('font-weight', 'bold');
      }
    })
  }

  document.querySelectorAll('table#notification-table tbody tr').forEach((el) => {
    el.addEventListener('click', (ev) => {
      $.ajax($(el).attr('data-href'))
    })
  })

  $('div.progress-row').hide();

  if ($('.company-autocomplete').length) {


    let autocompleteInputs = document.querySelectorAll('#user_company_attributes_name')
    M.Autocomplete.init(autocompleteInputs, {
      minLength: 2,
      onAutocomplete: () => {
        // # get address attributes
        $.get('/admin/get-company-info/' + $('#user_company_attributes_name').val(), (company_info) => {
          // # prepopulate fields
          $('#user_company_attributes_id').val(company_info.id)
          $('#user_company_attributes_primary_address_attributes_id').val(company_info.primary_address.id)
          $('#user_company_attributes_aib_number').val(company_info.aib_number)
          $('#user_company_attributes_issuing_body').val(company_info.issuing_body)
          $('#user_company_attributes_swissgrid_id').val(company_info.swissgrid_id)
          $('#user_company_attributes_primary_address_attributes_street_name').val(company_info.primary_address.street_name)
          $('#user_company_attributes_primary_address_attributes_street_number').val(company_info.primary_address.street_number)
          $('#user_company_attributes_primary_address_attributes_additional_info').val(company_info.primary_address.additional_info)
          $('#user_company_attributes_primary_address_attributes_zip').val(company_info.primary_address.zip)
          $('#user_company_attributes_primary_address_attributes_city').val(company_info.primary_address.city)
          $('#user_company_attributes_primary_address_attributes_phone').val(company_info.primary_address.phone)
          $('#user_company_attributes_primary_address_attributes_mobile').val(company_info.primary_address.mobile)
          $('#user_company_attributes_primary_address_attributes_iban').val(company_info.primary_address.iban)
          M.updateTextFields()
        })
      }
    })

    let autocompleteInput = document.querySelector('#user_company_attributes_name')
    let autocompleteInstance = M.Autocomplete.getInstance(autocompleteInput)

    const getCompanyData = (request) => {
      $.ajax({
        url: '/admin/search-companies',
        dataType: 'json',
        data: {
          term: request,
        },
        success: (data) => {
          autocompleteInstance.updateData(data)
        }
      })
    }

    autocompleteInput.addEventListener('keyup', () => {
      getCompanyData(autocompleteInput.value)
      autocompleteInstance.open()
    })

  }
});

$(window).on('load', function() {
  let tooltippedElems = document.querySelectorAll('.tooltipped')
  tooltippedElems.forEach((el) => {
    M.Tooltip.init(el, {
      enterDelay:50,
      unsafeHTML: el.dataset.tooltiphtml
    })
  })

  // delay displaying this, because it glitches out sometimes while loading.
  $('.switch, .switch-old').fadeIn(400);
  $('.menu-slidein').css({transition: 'transform 0.4s linear'});

  // Get IE or Edge browser version
  var version = detectIE();

  if (version !== false) {
    $('#iemodal').addClass('open').css({
      'z-index': 1003,
      display: 'block',
      opacity: '1',
      top: '10%',
      transform: 'scaleX(1) scaleY(1)'
    });

    var browser = version < 12 ? "Internet Explorer" : "Edge";

    var iemodal = '\
      <div id="iemodal" class="modal" style="display:block;opacity:1;top:10%;transform:scaleX(1) scaleY(1);z-index:1003;"> \
        <div class="modal-content"> \
          <p style="margin-bottom: 1em;">Sie nutzen den Webbrowser Microsoft ' + browser + ' ' + version + '. Dieser Webbrowser wird von oekostromboerse.ch nicht mehr unterstützt.</p> \
          <p  style="margin-bottom: 1em;">Wir empfehlen Ihnen für die Bedienung der Ökostrombörse auf eine aktuelle Version des Webbrowsers <a href="https://www.google.com/chrome/" target="_blank" style="text-decoration: underline;">Google Chrome</a> umzusteigen. Weitere Alternativen sind z.B.:</p> \
          <ul> \
            <li>Apple Safari (Standardbrowser für Mac OS)</li> \
            <li><a href="https://www.mozilla.org/de/firefox/" target="_blank" style="text-decoration: underline;">Mozilla Firefox</a></li> \
            <li><a href="https://www.opera.com/de" target="_blank" style="text-decoration: underline;">Opera</a></li> \
          </ul> \
          <p style="margin-bottom: 1em;">Bitte schicken Sie uns eine Email unter <a href="mailto:info@oekostromboerse.ch" style="text-decoration: underline;">info@oekostromboerse.ch</a> wenn Sie Fragen haben oder Unterstützung brauchen.</p> \
          <p style="margin-bottom: 1em;">Ihre Ökostrombörse</p> \
        </div> \
      </div><div class="modal-overlay" style="z-index: 1002; display: block; opacity: 0.5;"></div>';

    $('body').append(iemodal);
    $('body').css('overflow', 'hidden');
  }
});

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}
