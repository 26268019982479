
$(document).ready(() => {
  $("form#new_bid").on('ajax:error', (e) => {
    let detail = event.detail
    let data = detail[0]
    let status = detail[1]
    let xhr = detail[2]
    let errors = JSON.parse(xhr.responseText)
    let er = '<ul>'
    let i = 0
    while (i < errors.length) {
      let list = errors[i]
      er += '<li class="red-text">' + list + '</li>'
      i++
    }
    er += '</ul>'
    $('#errors').html(er)
  })

  $('.spinner-wrap').hide()

  $('form.new_bid').on('submit', () => {
    let quantity = parseFloat($('form.new_bid').find('#bid_quantity_taken').val()).toLocaleString('de-CH', {maximumFractionDigits: 20})
    let price = parseFloat($('form.new_bid').find('#bid_max_price').val()).toLocaleString('de-CH', {maximumFractionDigits: 20})
    let tech = document.auction.technology
    if (confirm("Sind Sie sicher, dass Sie " + quantity + " MWh " + tech + "-HKN zu einem Preis von maximal " + price + " CHF/MWh kaufen wollen?")) {
      $('.bid-form').hide()
      $('.spinner-wrap').show()
      return true
    } else {
      return false
    }
  })

  $('form.edit_bid').on('submit', () => {
    let quantity = parseFloat($('form.edit_bid').find('#bid_quantity_taken').val()).toLocaleString('de-CH', {maximumFractionDigits: 20})
    let price = parseFloat($('form.edit_bid').find('#bid_max_price').val()).toLocaleString('de-CH', {maximumFractionDigits: 20})
    let tech = document.auction.technology
    if (confirm("Sind Sie sicher, dass Sie " + quantity + " MWh " + tech + "-HKN zu einem Preis von maximal " + price + " CHF/MWh kaufen wollen?")) {
      $('.bid-form').hide()
      $('.spinner-wrap').show()
      return true
    } else {
      return false
    }
  })

  if ($('input#auction_type').val() == 'ReverseAuction') {
    $('input#auction').prop('checked', true)
  }

  $('input#auction').click((ev) => {
    let that = $(ev.target)
    if (that.is(':checked')) {
      $('input#auction_type').val('ReverseAuction').trigger('change')
    } else {
      $('input#auction_type').val('ForwardAuction').trigger('change')
    }
  })

  $('input#auction_type').change((ev) => {
    let that = $(ev.target)
    that.closest('form').submit()
  })

  $('.auction-filters select').each((i, el) => {
    $(el).change((ev) => {
      let search = new URLSearchParams(document.location.search)
      let type = ev.target.id
      let value = ev.target.value
      if (value == "") {
        search.delete(type)
      } else {
        search.set(type, value)
      }
      document.location.href = document.location.origin + document.location.pathname + "?" + search.toString()
    })
  })

  $('#reset-filters').click(() => {
    let search = new URLSearchParams(document.location.search)
    search.delete('technology')
    search.delete('production_year')
    search.delete('origin')
    search.delete('quality')
    document.location.href = document.location.origin + document.location.pathname + "?" + search.toString()
  })
})
