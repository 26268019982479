
const countdown = (endDate) => {
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  endDate = new Date(endDate).getTime();

  if (isNaN(endDate)) {
    return;
  }

  setInterval(() => {
    let startDate = new Date();
    startDate = startDate.getTime();
    let timeRemaining = parseInt((endDate - startDate) / 1000);
    if (timeRemaining > 0) {
      days = parseInt(timeRemaining / 86400);
      timeRemaining = timeRemaining % 86400;
      hours = parseInt(timeRemaining / 3600);
      timeRemaining = timeRemaining % 3600;
      minutes = parseInt(timeRemaining / 60);
      timeRemaining = timeRemaining % 60;
      seconds = parseInt(timeRemaining);
      $('#time').text("d" + parseInt(days, 10) + ":h" + ('0' + hours).slice(-2) + ":m" + ('0' + minutes).slice(-2) + ":s" + ('0' + seconds).slice(-2));
    } else if (timeRemaining == 0) {
      setTimeout(document.location.reload(), 1000);
      $('#time').text("d0:h00:m00:s00");
      $("#offer_forms").empty();
      $(".bid-form").empty();
      $(".time-span-text").empty().append("<span class='white-text'>Auktion beendet</span>");
    }
  }, 1000);
}

$(document).ready(() => {
  let quantity = 0
  let price = 0
  let tech = ""
  let units = []

  $('select#offer_plant_id option').each((i, el) => {
    if ($(el).is(':disabled')) {
      let val = $(el).text() + " (" + document.osb_unusable_plants[$(el).val()] + ")"
      $(el).text(val)
    }
  })

  var selectElems = document.querySelectorAll('select#offer_plant_id');
  var instances = M.FormSelect.init(selectElems, {
    dropdownOptions: {
      container: document.body
    }
  });

  $('.spinner-wrap').hide();

  $('form.new_offer').on('submit', () => {
    if ($('form.new_offer').find('#offer_plant_id').length > 0) {
      if (document.reverse_auction.auction_type == "pro") {
        quantity = (parseInt($('form.new_offer').find('#offer_plant_id option:selected').data('amount'), 10) / 1000).toLocaleString('de-CH')
      } else {
        quantity = parseInt($('form.new_offer').find('#offer_plant_id option:selected').data('amount'), 10).toLocaleString('de-CH')
      }
    } else {
      if ($('form.new_offer').find('[name="offer[delivery_quantity]"]').hasClass('decimal')) {
        quantity = parseFloat($('form.new_offer').find('[name="offer[delivery_quantity]"]').val()).toLocaleString('de-CH', {maximumFractionDigits: 20})
      } else {
        quantity = parseInt($('form.new_offer').find('[name="offer[delivery_quantity]"]').val(), 10).toLocaleString('de-CH')
      }
    }

    price = parseFloat($('form.new_offer').find('[name="offer[min_price]"]').val()).toLocaleString('de-CH', {maximumFractionDigits: 20})
    tech = document.reverse_auction.technology
    if (document.reverse_auction.auction_type == "pro")
      units = ["MWh", "CHF/MWh"]
    else
      units = ["kWh", "Rp./kWh"]
    if (confirm("Sind Sie sicher, dass Sie " + quantity + " " + units[0] + " " + tech + "-HKN zu einem Preis von mindestens " + price + " " + units[1] + " verkaufen wollen?")) {
      $('#offer_forms').hide()
      $('.spinner-wrap').show()
      return true
    } else {
      return false
    }
  })

  $('form.edit_offer').on('submit', () => {
    if ($('form.edit_offer').find('#offer_plant_id').length > 0) {
      if (document.reverse_auction.auction_type == "pro") {
        quantity = (parseInt($('form.edit_offer').find('#offer_plant_id option:selected').data('amount'), 10) / 1000).toLocaleString('de-CH')
      } else {
        quantity = parseInt($('form.edit_offer').find('#offer_plant_id option:selected').data('amount'), 10).toLocaleString('de-CH')
      }
    } else {
      if ($('form.edit_offer').find('[name="offer[delivery_quantity]"]').hasClass('decimal')) {
        quantity = parseFloat($('form.edit_offer').find('[name="offer[delivery_quantity]"]').val()).toLocaleString('de-CH', {maximumFractionDigits: 20})
      } else {
        quantity = parseInt($('form.edit_offer').find('[name="offer[delivery_quantity]"]').val(), 10).toLocaleString('de-CH')
      }
    }
    price = parseFloat($('form.edit_offer').find('[name="offer[min_price]"]').val()).toLocaleString('de-CH', {maximumFractionDigits: 20})
    tech = document.reverse_auction.technology
    if (document.reverse_auction.auction_type == "pro") {
      units = ["MWh", "CHF/MWh"]
    } else {
      units = ["kWh", "Rp./kWh"]
    }
    if (confirm("Sind Sie sicher, dass Sie " + quantity + " " + units[0] + " " + tech + "-HKN zu einem Preis von mindestens " + price + " " + units[1] + " verkaufen wollen?")) {
      $('#offer_forms').hide()
      $('.spinner-wrap').show()
      return true
    } else {
      return false
    }
  })

  // refresh form on plant_change
  $('select#offer_plant_id').change(() => {
    let reverse_auction = $('#offer_reverse_auction_id').val()
    let url = '/refresh-offer?plant_id=' + $('select#offer_plant_id').val() + "&&reverse_auction_id=" + reverse_auction
    $.get(url)
  })

  $('tr[data-link]').click((ev) => {
    if (ev.target.tagName !== "A") {
      let tr = $(ev.target).closest('tr')
      history.pushState(null, "", window.location.href)
      window.location.replace(tr.data('link'))
    }
  })

  let date = $("#auction_end_time").text()
  countdown(date)

  $("form#new_offer").on('ajax:error', (e) => {
    let detail = event.detail
    let data = detail[0]
    let status = detail[1]
    let xhr = detail[2]
    let errors = JSON.parse(xhr.responseText)
    let er = '<ul>'
    let i = 0
    while (i < errors.length) {
      let list = errors[i]
      er += '<li class="red-text">' + list + '</li>'
      i++
    }
    er += '</ul>'
    $('#errors').html(er)
  })
})
